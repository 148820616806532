import React, { useState } from "react";
import { Col } from "react-bootstrap";
import contactStyle from "../../components/non-panels/ContactForm.module.scss";

//components
import PanelFoundation from "../../components/panels/PanelFoundation";
import HubSpotForm from "../../components/non-panels/forms/HubSpotForm";
import Hero from "../../components/panels/Hero";
import ComponentHeader from "../../components/panels/ComponentHeader";
import SwitchComponent from "../../components/panels/Switch";
import useSetPageData from "../../utils/useSetPageData";
import ListItem from "../../components/panels/ListItem";
import ListItem2 from "../../components/panels/ListItem2";
import SEO from "../../components/seo";

// images
import Production_Technology_Integration_Maintenance from "../../images/technology-integration/Production_Technology_Integration_Maintenance.png";
import Technology_Integration_Cloud_Automation from "../../images/technology-integration/Technology_Integration_Cloud_Automation.png";
import Technology_Integration_Enhancement_Services from "../../images/technology-integration/Technology_Integration_Enhancement_Services.png";
import Technology_Integration_Maintenance_1 from "../../images/technology-integration/Technology_Integration_Maintenance_1.jpg";
import Technology_Integration_Maintenance_2 from "../../images/technology-integration/Technology_Integration_Maintenance_2.jpg";
import Technology_Integration_maintenance_dashboard from "../../images/technology-integration/Technology_Integration_maintenance_dashboard.png";
import Technology_Integration_Maintenance_Patches from "../../images/technology-integration/Technology_Integration_Maintenance_Patches.png";
import Technology_Integration_Monitoring_integration from "../../images/technology-integration/Technology_Integration_Monitoring_integration.png";
import Technology_Integration_On_Prem from "../../images/technology-integration/Technology_Integration_On_Prem.png";
import Technology_Integration_Production_Support from "../../images/technology-integration/Technology_Integration_Production_Support.png";
import Technology_Integration_Reporting_issues from "../../images/technology-integration/Technology_Integration_Reporting_issues.png";
import Technology_Integration_Working_collaboratively from "../../images/technology-integration/Technology_Integration_Working_collaboratively.png";
import rpaIcon from "../../images/manage-rpa/icon.png";
//styles
import s from "./technology-integration-managed-support-services.module.scss";

export const BlogSubscribeFormReplacementMessage = () => (
  <div
    className={contactStyle.thanks}
    style={{
      justifyContent: "center",
      alignItems: "center",
      alignSelf: "center"
    }}
  >
    <h5
      style={{
        textAlign: "center",
        justifyContent: "center",
        alignItems: "center",
        alignSelf: "center"
      }}
    >
      Thank You!
    </h5>
    <p style={{ textAlign: "center" }}>
      We will reach out to you soon regarding your quote.
    </p>
  </div>
);

const TechnologyIntegrationManagedSupportServices = ({ location, data }) => {
  useSetPageData(location);

  const [showModal, setShowModal] = useState(false);

  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);

  const showFormOverlay = value => {
    let form = document.querySelector("#hubSpotFormOverlay");
    if (value) {
      form.style.display = "block";
    } else {
      form.style.display = "none";
    }
  };

  return (
    <>
      <SEO
        {...{
          title: `Managed Services Provider for Support and Maintenance of Technology Integrations | NITCO Inc.`,
          description:
            "Managed Services Provider for Support and Maintenance for Boomi, Jitterbit, and IBM (Sterling) | NITCO Inc."
        }}
      />

      <Hero
        heading={"Technology Integration Managed Support Services"}
        iconComponent={<img src={rpaIcon} />}
        // icon={hero[0].service_icon.childImageSharp.fixed}
        // videoImage={hero[0].services_main_image.childImageSharp.fixed.src}
        // videoLink={"/videos/final-consulting.mp4"}
      />
      <ComponentHeader
        rootClassName={`bg-light text-primary`}
        text={`Managed Services Provider for Support and Maintenance of Technology Integrations`}
      />
      {/* <div style={{ marginTop: "40px" }}></div> */}
      <SwitchComponent
        evenClassName={`bg-light text-primary pt-5 pb-5`}
        oddClassName={`bg-light text-primary pt-5 pb-5`}
        contents={[
          {
            heading:
              "Technology Integration Managed Services Can Reduce IT Workload for Support and Maintenance",
            image: <img src={Technology_Integration_Maintenance_1} />,
            // image: <Image fluid={it_overview_image.childImageSharp.fluid} />,
            content: (
              <p>
                Technology integration solutions like Boomi, Jitterbit, and IBM
                Cloud Pak or Sterling offer amazing application, data,
                B2B/electronic data interchange (EDI) integration, API
                management, and master data management (MDM). You will want the
                skills and expertise of our talented NITCO resources to maximize
                the ROI on these enterprise integration platforms as a service
                (iPaaS) offer low code/no-code solutions.
                <br /> <br />
                We help many clients in multiple industries to get up and
                running with their integrations faster in weeks rather than
                months because this is a core of our service offerings. We have
                also partnered with the best of breed technology integration
                solutions.
                <br /> <br />
                We get it! We have partnered with the Gartner recognized
                best-of-breed technology integration solutions. Outsourcing your
                technology integration support and maintenance to NITCO Inc. can
                be a great way to reduce your IT department’s workload demands.
              </p>
            )
            // <ReactMarkdown
            //   source={overview_description}
            //   escapeHtml={false}
            //   renderers={{ link: LinkRenderer }}
            // />
          },
          {
            heading:
              "Why Your Organization Needs Technology Integration Managed Services?",
            image: <img src={Technology_Integration_Maintenance_2} />,
            // image: <Image fluid={it_overview_image.childImageSharp.fluid} />,
            content: (
              <p>
                Your IT staff already have a lot of work on their plates. They
                are responsible for managing the overall information
                infrastructure of the business while also being tasked with
                planning for and scaling the organization's future growth and
                leveraging the IT function as a core competency. Routine support
                and maintenance of automated processes is not a great use of
                your valuable resources' time. That is where we come in with the
                expertise and experience to support you and your organization
                <br /> <br />
                At NITCO, we believe keeping your Technology Integrations
                running smoothly, efficiently, and reliably is our job. You can
                engage your IT team in other strategic initiatives. NITCO’s
                Technology Integration managed IT services can provide:
              </p>
            )
          }
        ]}
      />

      <PanelFoundation rootClassName={`bg-light text-primary pt-0 pb-5`}>
        <div className={"table"}>
          <div className={"row"}>
            <div className={"col"}>
              <Col sm={12}>
                <ul className={`one-column`}>
                  <li>
                    Proactive monitoring of Technology Integration platform and
                    scheduled processes
                  </li>
                  <li>Comply with defined Service Level Agreements (SLAs)</li>
                  <li>Business hours only or 24/7/365 customer support</li>
                  <li>Optimization of integration processes</li>
                </ul>
              </Col>
            </div>
            <div className={"col"}>
              {/* <Col sm={6}> */}
              <ul className={`one-column`}>
                <li>Consistent and transparent issue tracking</li>
                <li>Scalable pricing</li>
                <li>Enhancement/development for existing integrations</li>
              </ul>
              {/* </Col> */}
            </div>
          </div>
        </div>
      </PanelFoundation>

      <ComponentHeader
        rootClassName={`bg-white`}
        textClassName={`text-primary`}
        text={"What is Technology Integration Managed Services?"}
      />
      <PanelFoundation>
        <Col sm={12}>
          <p>
            NITCO’s Technology Integration Managed Services represent a suite of
            service offerings available to manage and maintain your Technology
            Integration platform and processes. These include:
          </p>
        </Col>
      </PanelFoundation>

      <ListItem
        rootClassName={`pb-5`}
        columnsPerRow={2}
        shouldRenderLeft
        // hasCircle
        // circleBorderColor={`var(--primary)`}
        contents={[
          {
            image: (
              <img
                src={Technology_Integration_Reporting_issues}
                style={{ width: "75px" }}
              />
            ),
            content: `Monitoring integration processes for errors, exceptions, and availability`
            // heading: "Heading",
          },
          {
            image: (
              <img
                src={Technology_Integration_Production_Support}
                style={{ width: "75px" }}
              />
            ),
            content: `Providing a support and maintenance dashboard`
            // heading: "Heading",
          },

          {
            image: (
              <img
                src={Technology_Integration_maintenance_dashboard}
                style={{ width: "75px" }}
              />
            ),
            content: `Reporting issues and tracking the progress of issue resolution`
            // heading: "Heading",
          },

          {
            image: (
              <img
                src={Technology_Integration_Working_collaboratively}
                style={{ width: "75px" }}
              />
            ),
            content: `Working collaboratively to scale up the use of integration processes`
            // heading: "Heading",
          }
        ]}
      />

      <ComponentHeader
        rootClassName={`bg-white`}
        textClassName={`text-primary`}
        text={
          "On-Prem and Technology Integration Cloud Automation Support and Maintenance"
        }
      />
      <PanelFoundation rootClassName={`panel-padding pb-0 pt-1`}>
        <Col sm={12}>
          <p>
            NITCO’s experienced teams can provide Technology Integration managed
            support and maintenance for both on-prem and cloud-implemented
            solutions.
          </p>
        </Col>
      </PanelFoundation>

      <ListItem
        rootClassName={s.newCard}
        itemStyle={{ textAlign: "left" }}
        columnsPerRow={2}
        isCard
        headingContainerClassName={`${s.newCardImg}`}
        // hasCircle
        // circleBorderColor={`var(--primary)`}
        contents={[
          {
            image: (
              <img
                src={Technology_Integration_On_Prem}
                style={{ width: "100px" }}
              />
            ),
            heading: "On-Prem",
            content:
              "In an on-prem environment, your company hosts its Technology Integration applications on its hardware, and NITCO can provide support via VPN access to your company’s network."
          },
          {
            image: (
              <img
                src={Technology_Integration_Cloud_Automation}
                style={{ width: "100px" }}
              />
            ),
            heading: "Cloud",
            content:
              "With your cloud platform, your company hosts Technology Integration applications on the public or private cloud servers. NITCO manages the processes with the appropriate security access."
          }
        ]}
      />

      <ComponentHeader
        rootClassName={`bg-primary`}
        textClassName={`text-light`}
        text={"Why Choose NITCO for Technology Integration Managed Services?"}
      />
      <PanelFoundation rootClassName={`panel-padding bg-light pb-0`}>
        <Col sm={12}>
          <p>
            Selecting NITCO as your Technology Integration managed services
            partner allows you to delegate the day-to-day support and
            maintenance of your Technology Integration solution, so your IT
            teams can focus on more important work. As an established Technology
            Integration vendor, NITCO offers more reliability and availability
            than most companies find they can attain managing their Technology
            Integration platforms and processes on their own. Our consistent and
            customer-focused approach to issue tracking and resolution means
            your end users can count on the 24x7 availability of integration
            processes they need to complete the business transactions with your
            organization
          </p>
        </Col>
      </PanelFoundation>

      <ComponentHeader
        // rootClassName={`bg-light`}
        textClassName={`text-primary`}
        text={
          "How Our Technology Integration Support and Maintenance Services Work?"
        }
      />
      <PanelFoundation rootClassName={`panel-padding pb-0`}>
        <Col sm={12}>
          <p>
            NITCO’s support and maintenance services are clearly outlined in our
            Statements of Work (SOWs) and Service Level Agreements (SLAs). You
            know exactly what you are getting for your investment and have the
            information you need to ensure you’re getting the level of service
            and support you deserve. Our NITCO Inc Technology Integration
            Support and Maintenance services fall into four key areas:
          </p>
        </Col>
      </PanelFoundation>

      <ListItem2
        rootClassName={`${s.purpleBackground} pb-0`}
        columnsPerRow={2}
        isCard
        hasCircle
        circleBorderColor={`var(--primary)`}
        circleColor={`var(--primary)`}
        contents={[
          {
            image: (
              <img
                src={Production_Technology_Integration_Maintenance}
                style={{ width: "40px" }}
              />
            ),
            heading: `Production Technology Integration Maintenance/Lights-On Incident Support`,
            content: `We monitor and respond to incidents, correct errors and work with you to improve performance and reliability—adding, changing or removing functionality as necessary to support your applications.`
          },
          {
            image: (
              <img
                src={Technology_Integration_Monitoring_integration}
                style={{ width: "40px" }}
              />
            ),
            heading: `Technology Integration Maintenance / Production Support`,
            content: `We provide ongoing operation of your supported applications for both cyclical and non-cyclical activities. `
          },

          {
            image: (
              <img
                src={Technology_Integration_Maintenance_Patches}
                style={{ width: "40px" }}
              />
            ),
            heading: `Technology Integration Maintenance Patches (on-premise only)`,
            content: `We’ll apply patches to maintain system stability and fix issues when not in conflict with our support service or service level agreements.`
          },

          {
            image: (
              <img
                src={Technology_Integration_Enhancement_Services}
                style={{ width: "40px" }}
              />
            ),
            heading: `Technology Integration Enhancement Services: Enhancements/Process Development `,
            content: `We modify automated process to respond to changing or new business requirements.`
          }
        ]}
      />
      <PanelFoundation rootClassName={`panel-padding`}>
        <Col sm={12}>
          <p>
            Each client’s need is unique, so we work with you upfront to
            identify how we can best support you for the short and long term.
            After discussing your specific needs, NITCO will work with your
            staff to outline the requirements of our engagement in an SOW that
            documents:
          </p>
          <ul className={`pb-3 pt-3`}>
            <li>The specific services you’ll receive</li>
            <li>Who’s responsible for what—and when</li>
            <li>Expected Deliverables</li>
            <li>Monthly support and maintenance costs</li>
          </ul>
          <p>
            That’s the starting point, of course. NITCO understands that your
            needs may change as you grow and as the you scale up your automation
            practice. Our change process is flexible and agile enough to support
            your evolving needs.
          </p>
        </Col>
      </PanelFoundation>

      <ComponentHeader
        rootClassName={`pt-0`}
        textClassName={`text-primary`}
        text={"Choose From Standard and Premium Managed Support Service Plans"}
      />
      <PanelFoundation rootClassName={`panel-padding pb-0`}>
        <Col sm={12}>
          {/* //   <h3>
        //    Engage with NITCO’s  Team Today
        //  </h3> 
          <p>
            NITCO is an industry leader with years of experience assisting
            companies large and small with their  managed support and
            maintenance needs. Contact us today to discuss your  management
            needs and to receive a free estimate.
          </p> */}
        </Col>
      </PanelFoundation>

      <div className={`${s.pricing} pricing-palden`}>
        <div className="pricing-item">
          <div className="pricing-deco bg-primary">
            <div className="pricing-price">
              <h5 className="pricing-title">Premium</h5>
            </div>
          </div>
          <p style={{ width: "80%", margin: "1rem auto" }}>
            <strong>24/7/365 days</strong>
          </p>
          <ul className="pricing-feature-list">
            <li className="pricing-feature">
              <i className={`fas fa-check-circle text-success mr-3`}></i>Account
              Dashboard / Process Reporting
            </li>
            <li className="pricing-feature">
              <i className={`fas fa-check-circle text-success mr-3`}></i>
              Periodic Client Status Meetings
            </li>
            <li className="pricing-feature">
              <i className={`fas fa-check-circle text-success mr-3`}></i>
              Production Maintenance / Lights-On Incident Support
            </li>
            <li className="pricing-feature">
              <i className={`fas fa-check-circle text-success mr-3`}></i>
              Production Maintenence / Support
            </li>
            <li className="pricing-feature">
              <i className={`fas fa-check-circle text-success mr-3`}></i>
              Maintenance Patches (on-prem)
            </li>
            <li className="pricing-feature">
              <i className={`fas fa-check-circle text-success mr-3`}></i>
              Enhancement / Process Development (includes 20 hours / month)
            </li>
            <li className="pricing-feature">
              <i className={`fas fa-check-circle text-success mr-3`}></i>
              Hyper-Care (10 days complimentary each new
              integration/enhancement)
            </li>
          </ul>
          <button
            className="pricing-action"
            onClick={() => showFormOverlay(true)}
          >
            Request a Quote
          </button>
        </div>
        <div className="pricing-item ">
          <div className="pricing-deco bg-primary">
            <div className="pricing-price">
              <h4 className="pricing-title">Standard</h4>
            </div>
          </div>
          <p style={{ width: "80%", margin: "1rem auto" }}>
            <strong>
              US Office Hours, 8a-5p CST,{" "}
              <span style={{ whiteSpace: "nowrap" }}>No Holidays</span>
            </strong>
          </p>
          <ul className="pricing-feature-list">
            <li className="pricing-feature">
              <i className={`fas fa-check-circle text-success mr-3`}></i>Account
              Dashboard / Process Reporting
            </li>
            <li className="pricing-feature">
              <i className={`fas fa-check-circle text-success mr-3`}></i>
              Periodic Client Status Meetings
            </li>
            <li className="pricing-feature">
              <i className={`fas fa-check-circle text-success mr-3`}></i>
              Production Maintenance / Lights-On Incident Support
            </li>
            <li className="pricing-feature">
              <i className={`fas fa-check-circle text-success mr-3`}></i>
              Production Maintenence / Support
            </li>
            <li className="pricing-feature">
              <i className={`fas fa-check-circle text-success mr-3`}></i>
              Maintenance Patches (on-prem)
            </li>
            <li className="pricing-feature">
              <i className={`fas fa-check-circle text-success mr-3`}></i>
              Enhancement / Process Development (add on)
            </li>
            <li className="pricing-feature">
              <i className={`fas fa-check-circle text-success mr-3`}></i>
              Hyper-Care (10 days complimentary each new
              integration/enhancement)
            </li>
          </ul>
          <button
            className="pricing-action"
            onClick={() => showFormOverlay(true)}
          >
            Request a Quote
          </button>
        </div>
      </div>

      <ComponentHeader
        rootClassName={`pt-0`}
        textClassName={`text-primary`}
        text={"Engage with NITCO’s Technology Integration Team Today "}
      />
      <PanelFoundation rootClassName={`panel-padding pb-0`}>
        <Col sm={12}>
          <p>
            NITCO is an industry leader with years of experience assisting
            companies large and small with their Technology Integration managed
            support and maintenance needs.
            <strong>
              <a href="mailto:YourDigitalTechnologyPartner@NitcoInc.com?subject=Technology%20Integration%20Support%20and%20Maintenance%20Estimate">
                {" "}
                Contact us today{" "}
              </a>
            </strong>
            to discuss your Technology Integration management needs and to
            receive a complimentary assessment.
          </p>
        </Col>
      </PanelFoundation>
      <br />
      <br />
      <br />

      <div id="hubSpotFormOverlay" className={`${s.hubSpotFormOverlay}`}>
        <div className={`${s.hubSpotFormOverlayDiv}`}>
          <div className={`${s.hubSpotFormHeader}`}>
            <span className={`${s.hubSpotFormHeaderDescription}`}>
              Request a new Quote
            </span>
            <span
              className={`${s.hubSpotFormOverlayCloseButton}`}
              onClick={() => {
                showFormOverlay(false);
              }}
            >
              X
            </span>
          </div>
          <HubSpotForm
            formId="c052d59c-c46f-4821-a9d7-a3b5e73f68cb"
            replacementElement={
              <div
                className={"container"}
                style={{ justifyContent: "center", alignItems: "center" }}
              >
                <div
                  className={`container`}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                  }}
                >
                  <BlogSubscribeFormReplacementMessage />
                </div>
                <div
                  className={"container"}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                  }}
                >
                  <button
                    className="btn btn-primary ml-4"
                    onClick={() => {
                      showFormOverlay(false);
                    }}
                  >
                    Close
                  </button>
                </div>
              </div>
            }
          />
        </div>
      </div>
    </>
  );
};

export default TechnologyIntegrationManagedSupportServices;
